//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue";
import BoldImage from "~/bold/components/atoms/BoldImage/BoldImage.vue";
import Player from '@vimeo/player';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "BoldGalleryItem",
  components: {
    SfButton,
    SfLink,
    BoldImage
  },
  data() {
    return {
      vimeo: {
        player: null,
      },
      youtube: {
        player: null,
      },
      youtubePlayer: null,
      vimeoPlayer: null,
    };
  },
  props: {
    background: {
      type: String,
      default: "",
    },
    focused: {
      type: Boolean,
      default: false,
    },
    image: {
      type: [Object, String],
      default: "",
    },
    imageTag: {
      type: String,
      default: "",
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    mediaType: {
      type: String,
      default: "",
    },
    videoContentData: {
      type: Object,
      default: null,
    },
    pdpImage: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    if (this.isVimeo) {
      if (this.$refs.vimeoPlayer)
        this.vimeo.player = new Player(this.$refs.vimeoPlayer, {
          id: this.videoContentData.video_url,
          width: 640
        });
      else console.warn("No vimeo player element ref found.");
    }
    if (this.isYouTube) {
      if (this.$refs.youtubePlayer)
        this.initializeYT();
      else console.warn("No youtube player element ref found.");
    }
  },
  methods: {
    // If YT is already initialized, just calls the ready method
    // Otherwise, waits for the YT api to be ready, adds a callback for when the API is ready, and creates a script on the page with YouTube's script for managing the player iframe
    initializeYT() {
      if (typeof window.YT !== "undefined") {
        this.YTIframeApiReady();
        return;
      }

      this.waitForYTApiReady();

      window.onYouTubeIframeAPIReady = () => {
        window.YTIframeApiReadyCallbacks.forEach(YTIFrameApiReadyCallback => {
          YTIFrameApiReadyCallback();
        });
      }

      if (document.getElementById("yt-iframe-api-script")) return;

      const tag = document.createElement("script");

      tag.id = "yt-iframe-api-script";
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    },
    // If YTIframeApiReadyCallbacks doesn't exist yet, create empty array. Regardless, push a callback
    // for when the YT iframe is ready
    waitForYTApiReady() {
      if (typeof window.YTIframeApiReadyCallbacks === "undefined") {
        window.YTIframeApiReadyCallbacks = [];
      }

      window.YTIframeApiReadyCallbacks.push(() => {
        this.YTIframeApiReady();
      });
    },
    // Called when the YT iframe is ready, and then creates a new player for the current YouTube video
    YTIframeApiReady() {
      this.youtube.player = new YT.Player(this.$refs.youtubePlayer, {
        videoId: this.youTubeId,
        playerVars: {
          'playsinline': 1, // IMPORTANT FOR IOS!
        },
      });
    },
  },
  computed: {
    isImage() {
      return this.mediaType !== "external-video";
    },
    isVimeo() {
      return this.mediaType === "external-video" && this.videoContentData.video_url.indexOf("vimeo.com") !== -1;
    },
    isYouTube() {
      return this.mediaType === "external-video" && this.videoContentData.video_url.indexOf("youtube.com") !== -1;
    },
    youTubeId() {
      if (!this.isYouTube) return null;
      const regexResult = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?(?<youtubeId>[\w-]{11})((&([A-Za-z0-9]+)?)*)?$/.exec(this.videoContentData.video_url);
      return regexResult?.groups?.youtubeId;
    },
    pdpImageClass() {
      if (!this.pdpImage)
        return "";
      return "max-w-pdp-image max-h-pdp-image";
    }
  },
  watch: {
    // Pause Vimeo when the gallery item is no longer in focus
    focused() {
      if (this.isVimeo) {
        this.vimeo.player?.pause();
      }
      if (this.isYouTube) {
        this.youtube.player?.pauseVideo();
      }
    }
  }
};

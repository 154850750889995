//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import BoldGalleryItem from "./BoldGalleryItem.vue";
import SfArrow from "@storefront-ui/vue/src/components/atoms/SfArrow/SfArrow.vue";
import BoldBullets from "~/bold/components/atoms/BoldBullets/BoldBullets.vue";
import Glide from "@glidejs/glide";

Vue.component("BoldGalleryItem", BoldGalleryItem);
export default {
  name: "BoldGallery",
  components: {
    SfArrow,
    BoldBullets,
  },
  props: {
    sliderOptions: {
      type: Object,
      default: () => ({}),
    },
    pages: {
      type: Number,
      default: 1,
    },
    setPage: {
      type: Number,
      default: -1,
    },
    active: {
      type: Boolean,
      default: true,
    },
    disableKeyboardWhenInactive: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      glide: null,
      defaultOptions: {
        type: "slider",
        rewind: true,
        autoplay: 5000,
        perView: 1,
        gap: 0,
      },
    };
  },
  computed: {
    mergedOptions() {
      return {
        ...this.defaultOptions,
        ...this.sliderOptions,
      };
    },
    numberOfPages() {
      return this.pages
          ? this.pages
          : 1;
    },
    page() {
      if (this.glide) {
        return this.glide.index + 1;
      }
      return 1;
    },
    hideControls() {
      return this.numberOfPages <= 1;
    }
  },
  mounted() {
    if (this.numberOfPages > 1) {
      this.$nextTick(() => {
        const glide = new Glide(
          this.$refs.glide,
          {
            ...this.mergedOptions,
            ...(this.disableKeyboardWhenInactive ? { keyboard: this.active } : {})
          }
        );
        glide.mount();
        this.glide = glide;
        this.glide.on('run', () => {
          this.$emit("changePage", this.glide.index); // Only emits when we deliberately change the page
        });
      });
    }
  },
  emits: ['changePage'],
  methods: {
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case "prev":
          this.glide.go("<");
          break;
        case "next":
          this.glide.go(">");
          break;
        default:
          this.glide.go(`=${direct}`);
          break;
      }
    },
    noop(){}
  },
  watch: {
    setPage() {
      this.glide.go(`=${this.setPage}`);
    },
    active() {
      if (this.disableKeyboardWhenInactive) {
        this.glide.update({
          keyboard: this.active,
        });
      }
      if (!this.active && this.setPage !== -1) {
        this.glide.go(`=${this.setPage}`);
      }
    }
  }
};
